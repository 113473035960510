<script>
	import Fa from 'svelte-fa'
  import Dispatch from '../../../Dispatcher'
	// import { faLockAlt, faWallet, faHandHoldingUsd } from '@fortawesome/pro-solid-svg-icons'
	import { faBars, faGlobePointer } from '@fortawesome/pro-regular-svg-icons'
	import { of, animationFrames, timer, merge } from 'rxjs'
  import { map, tap, mergeMap, pairwise, scan, share, startWith, switchMap, takeWhile, throttleTime, take } from 'rxjs/operators'
  import _ from 'lodash'

  window?.gtag('event', 'purchase', {});
  window?.gtag('event', 'conversion_event_purchase', {
    // <event_parameters>
  });


</script>

<div class='h-100 mw6 mb5 ph4 pt4 br4 pa5-ns bg-happy-yellow flex flex-column justify-center'>
  <div class='w-100 flex justify-between'>
    <div></div>
    <div class='fw7 f3 red' on:click={e => Dispatch.nextState('showCart', false)}>X</div>
  </div>
  <div class='pt4 f4 fw5 black tl'>
    <div class='f2 fw7'>Congratulations!</div>
    <div class='pt3 f3 fw7'>The 2024 Scavenger Games Are About to Begin</div>
    <!-- <div class='f4 pt3 fw4'>The 2024 Scavenger Games are about to begin.</div> -->
    <div class='f4 pt5 fw4'>An email has been sent to you with your receipt details.</div>
    <div class='f4 pt3 fw4'>If you haven't already, make sure to fill your team. Each team consists of up to 6 players, but a minimum of 3 will be required.</div>
    <div class='f4 pt3 fw4'>Good luck!</div>
  </div>
</div>

<style>
</style>