<script>
  import '../../engines/cart.js'
	import Fa from 'svelte-fa'
  import Dispatch from '../../Dispatcher'
	// import { faLockAlt, faWallet, faHandHoldingUsd } from '@fortawesome/pro-solid-svg-icons'
	import { faBars, faGlobePointer, faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
	import { of, animationFrames, timer, merge } from 'rxjs'
  import { map, tap, mergeMap, pairwise, scan, share, startWith, switchMap, takeWhile, throttleTime, withLatestFrom, delay, shareReplay } from 'rxjs/operators'
  import _ from 'lodash'

  import VaultHandle from '../../assets/VaultHandle.png'
  import FlagOutline from '../../assets/FlagOutline.png'

  import Header from '../Header.svelte'

  import Unlock from './Questions/Unlock.svelte'
  import TeamQuestion from './Questions/Team.svelte'
  import TeamSelect from './Questions/TeamSelect.svelte'
  import CreateTeam from './Questions/CreateTeam.svelte'
  import NewUser from './Questions/NewUser.svelte'
  import AdditionalUser from './Questions/AdditionalUser.svelte'
  import Summary from './Questions/Summary.svelte'
  import Purchase from './Questions/Purchase.svelte'
  import Success from './Questions/Success.svelte' 
  import TeamManager from './Questions/TeamManager.svelte'

  const passcode$ = Dispatch.getState('passcode')

  const initialHeight$ = merge(
			of(window),
			Dispatch.getAction('resize')
		)
		.pipe(
			map(e => e.innerHeight - 99), 
		)

  const team$ = Dispatch.getStateUndefined('team')

  window?.gtag('event', 'select_item', {});

</script>

<div>
  <Header />
  <div id='tickets' class='w-100 bt b--black bw3 bg-black happy-yellow flex flex-column items-center justify-between' style={'min-height: ' + $initialHeight$ + 'px;'}>
    <!-- <div class='relative pt5 w-100 flex justify-center' style='z-index:10; transform: rotate(5deg);'>
      <div class='bg-black happy-yellow ph4 pv3 f1  fw8 br3'>GET</div>
      <div></div>
    </div>
    <div class='relative pt4 w-100 flex justify-center' style='z-index:10; transform: rotate(-5deg);'>
      <div class='bg-black happy-yellow ph4 pv3 f1  fw8 br3'>YOUR</div>
      <div></div>
    </div>
    <div class='relative pt4 w-100 flex justify-center' style='z-index:10;'>
      <div class='bg-black happy-yellow ph4 pv3 f1  fw8 br3'>TICKETS!</div>
      <div></div>
    </div> -->

      <!-- {#if $page$ !== 'Unlock' && $page$ !== 'Success' }
        <div class='w-100 pt3 pl4' on:click={e => Dispatch.nextAction('prevPage')}><Fa class='f1' icon={faArrowLeft} /></div>
      {:else if $page$ === 'Success'}
        <div></div>
      {/if}
      <div class='w-100 pt3 flex justify-center items-center h-100'>
        <svelte:component this={pages[$page$]?.value}/>
      </div> -->
      <div class='w-100 pt3 ph3 flex justify-center items-center h-100'>
        {#if $team$?.guid}
          <TeamManager />
        {:else}
          {#if !_.isNil($passcode$)}
            <div class='pa4 br4 bg-happy-yellow black fw7 f4'>
              <div>Loading...</div>
            </div>
          {:else}
            <CreateTeam />
          {/if}
        {/if}
      </div>
      <div></div>
    </div>
</div>

<!-- 
<div id='intro' class='vh-100 w-100 mw8 bg-black'>
  <div class='flex flex-column items-center justify-center'>
    <div class='pt5 w-100 fw7 f2 yellow tc'> Choose Your Flag </div>
    <div class='ph3 flex justify-between items-center yellow f5'>
      <div class='pr4'>Prev</div>
      <div class='pt4'><img class='w-100' src={FlagOutline} /></div>
      <div class='pl4'>Next</div>
    </div>
  </div>
</div> -->

<style>


</style>