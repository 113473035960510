<script>
	import Fa from 'svelte-fa'
  import Dispatch from '../Dispatcher'
	import { faCircleChevronRight, faCircleChevronLeft } from '@fortawesome/pro-solid-svg-icons'
	import { faBars } from '@fortawesome/pro-regular-svg-icons'
	import { of, animationFrames, timer, merge } from 'rxjs'
  import { map, tap, mergeMap, pairwise, scan, share, startWith, switchMap, takeWhile, throttleTime } from 'rxjs/operators'
  import _ from 'lodash'

  import JuneLaser from '../assets/JunePictures/JuneLaser.jpg'
	import JuneFlagHolding from '../assets/JunePictures/JuneFlagHolding.jpg'
	import JuneBalloonChallenge from '../assets/JunePictures/JuneBalloonChallenge.jpg'
	import JunePuzzleSolve from '../assets/JunePictures/JunePuzzleSolve.jpg'
	import JuneAnnouncer from '../assets/JunePictures/JuneAnnouncer.jpg'
  import JuneLaserVertical from '../assets/JunePictures/JuneLaserVertical.jpg'
  import JuneReading from '../assets/JunePictures/JuneReading.jpg'
  import JuneGenerator from '../assets/JunePictures/JuneGenerator.jpg'
  import AugustSauced from '../assets/AugustPictures/Sauced.jpg'

  const images = [AugustSauced, JuneLaserVertical, JuneReading, JuneGenerator, JuneAnnouncer, JunePuzzleSolve, JuneBalloonChallenge]
  let index = 0
</script>

<div class='relative w-100 mw6 mt4 br4 pa2 flex items-center justify-center bg-yellow' on:click={e => {
    if (index < images.length - 1) {
      index = index + 1
    }
    else {
      index = 0
    }
  }}>

    <img class='w-100 br3' src={images[index]} />
    <div class='w-100' style='top:50%; position: absolute;'>
      <div class='w-100 ph3 ph4-ns flex justify-between'>
        <div><Fa class='f2 f1-ns white o-50 o-70-ns' icon={faCircleChevronLeft} /></div>
        <div><Fa class='f2 f1-ns white o-50 o-70-ns ' icon={faCircleChevronRight} /></div>
      </div>
    </div>
</div>

<style>
  img {
    width: auto;
    max-height: 100%;
    object-fit: cover; /* Ensures images cover their box without stretching */
  }
</style>