<script>
	import Fa from 'svelte-fa'
  import Dispatch from '../Dispatcher'
	// import { faLockAlt, faWallet, faHandHoldingUsd } from '@fortawesome/pro-solid-svg-icons'
	import { faBars, faGlobePointer, faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
	import { of, animationFrames, timer, merge } from 'rxjs'
  import { map, tap, mergeMap, pairwise, scan, share, startWith, switchMap, takeWhile, throttleTime, withLatestFrom } from 'rxjs/operators'
  import _ from 'lodash'

  import VaultHandle from '../assets/VaultHandle.png'
  import FlagOutline from '../assets/FlagOutline.png'

  import Header from './Header.svelte'


  const initialHeight$ = merge(
			of(window),
			Dispatch.getAction('resize')
		)
		.pipe(
			map(e => e.innerHeight - 99), 
		)

</script>

<div>
  <Header />
  <div id='tickets' class='w-100 bt b--black bw3 bg-black happy-yellow flex flex-column items-center' style={'min-height: ' + $initialHeight$ + 'px;'}>
    <div class='w-100 mw6 pt4 fw7 f1 tc'>Privacy & Cookie Policy</div>
    <div class='mw6 ph3 pt3 pb5 yellow' style='white-space: pre-line;'>
      Last Updated: 5/3/2024
      
      East Troy Area Chamber of Commerce and its subsidiaries and affiliates (collectively, “Dither” “we,” “our,” and “us”) are responsible for www.easttroy.org www.happyvibegames.com, and www.scavengergames.com (the “Websites”). Your privacy is important to us. This Privacy Policy applies to the personal information we may collect from you across our Websites, our iOS/Android mobile application and other sites we own and operate (collectively, our “Services”).
     
      Please read this Privacy Policy carefully to understand our policies and practices regarding your personal information and how we will treat it. By accessing or using any component of our Websites or any part of the Services, you agree to all terms and conditions of this Privacy Policy and the changes thereto. If you do not agree, do not use or access the Websites, our App or our Services.
      
      What Personal Information We Collect
      Cookie Policy: How We Use Cookies or Other Tracking Technologies
      How We Use Your Personal Information
      How We Disclose or Otherwise Share Your Personal Information with Third Parties
      Where We Store Your Personal Information
      How Long We Retain Your Personal Information
      Your Rights Regarding Your Personal Information
      Do Not Track
      License
      Notification of a Data Breach
      Third Party Links
      Changes to this Privacy Policy
      Contact Us
      What Personal Information We Collect
      When you visit our Websites or engage with our Services, you may provide certain information about yourself that may help us learn about where you are seeking an experience and other information about yourself, including Personal Information, which is information that, individually or used in combination with other information, such as your name, may allow us to identify you as a natural person. This information may include any of the following:
      
      Name
      Email
      Social media profiles
      Date of birth
      Phone/mobile number
      Home/Mailing address
      Work address
      Payment information (though this information is collected by a third party and we do not actually retain any financial information)
      Geo-location data
      We may also collect information when you apply for employment opportunities. The Personal Information that you may be asked to provide during the application process includes, in addition to the data categories listed above, the following:
      
      Government identification document or number, including a driver's license
      Work history
      Education
      Resume or CV and/or a cover letter, including any information you provide in those documents
      Citizenship and/or permanent legal residence
      Eligibility to work, including documentation of immigration status
      Interest in employment opportunities
      Job-specific questions that relate to the fitness of a candidate for a particular job
      References (if you provide information regarding other individuals, you must obtain their consent before providing the data to us)
      Information necessary for us to confirm that your employment would not create a conflict of interest or otherwise violate applicable law or regulation
      Information that you may voluntarily choose to provide about your ethnicity, gender, sexual orientation, veteran status, and disability
      Information you provide to create username, password, security questions and answers used to validate your identity when you return to the Websites
      Where you learned about the job opening
      Any other information that you may choose to provide as part of your application
      Information provided by third-party sites, if you apply for a job opening through a third-party site
      We may also collect information about you, including the device you use to interact with our Websites and Services through cookies and similar technologies. This is what we call “Device Data” and includes the device type, operating system, unique device identifiers, device settings, and geo-location data. What we collect can depend on the individual settings of your device and software. We recommend checking the policies of your device manufacturer or software provider to learn what information they make available to us.
      
      Lastly, when you visit our Websites or use our Services, our servers may automatically log the standard data provided by your web browser, including your computer’s Internet Protocol (IP) address, your browser type and version, the pages you visit, the time and date of your visit, the time spent on each page, and other details.
      
      Cookie Policy: How We Use Cookies or Other Tracking Technologies
      We use “cookies” to collect information about you and your activity across our Websites and to help improve your experience with our Websites, App, and Services.
      
      A cookie is a small piece of data that our Websites stores on your computer and accesses each time you visit, so we can understand how you use our site. This helps us serve you content based on preferences you have specified. Please refer to our Cookie Policy for more information.
      
      What is a cookie?
      A cookie is a small piece of data that a Websites stores on your device when you visit, typically containing information about the Websites itself, a unique identifier that allows the site to recognize your web browser when you return, additional data that serves the purpose of the cookie, and the lifespan of the cookie itself. Cookies are used to enable certain features, e.g., logging in; to track site usage, e.g., analytics; store your user settings, e.g., time zone, notification preferences; and to personalize your content e.g., advertising, language.
      
      Who sets cookies?
      Cookies set by the Websites you are visiting are normally referred to as “first-party cookies,” and typically only track your activity on that particular site. Cookies set by other sites and companies, i.e., third parties are called “third-party cookies,” and can be used to track you on other websites that use the same third-party service.
      
      Types of Cookies We Employ on Our Websites
      Cookie Type	Description
      Essential Cookies	Essential cookies are crucial to your experience of the Websites, enabling core features like user logins, account management, shopping carts, and payment processing. We use essential cookies to enable certain functions on our Websites.
      Performance Cookies	Performance cookies are used in the tracking of how you use a Websites during your visit, without collecting Personal Information about you. Typically, this information is anonymous and aggregated with information tracked across all site users, to help companies understand visitor usage patterns, identify and diagnose problems or errors their users may encounter, and make better strategic decisions in improving their audience’s overall Websites experience. These cookies may be set by the Websites you’re visiting (first-party) or by third-party services.
      Functionality Cookies	Functionality cookies are used in collecting information about your device and any settings you may configure on the Websites you’re visiting, e.g., like language and time zone settings. With this information, the Websites can provide you with customized, enhanced, or optimized content and services. These cookies may be set by the Websites you’re visiting (first-party) or by third-party service.
      Targeting/Advertising Cookies	Targeting/advertising cookies are used in determining what promotional content is more relevant and appropriate to you and your interests. We may use them to deliver targeted advertising or to limit the number of times you see an advertisement. This helps us improve the effectiveness of their campaigns and the quality of content presented to you. These cookies may be set by the Websites you’re visiting (first-party) or by third-party services. Targeting/advertising cookies set by third-parties may be used to track you on other websites that use the same third-party service.
      Third Party Cookies	We may employ third-party companies and individuals on our Websites, e.g., analytics providers and content partners. We grant these third parties access to selected information to perform specific tasks on our behalf. They may also set third-party cookies in order to deliver the services they are providing. Third-party cookies can be used to track you on other websites that use the same third-party service. As we have no control over third-party cookies, they are not covered by Dither's cookie policy.
      If you do not wish to accept cookies from us, you should instruct your browser to refuse cookies from the Websites, with the understanding that we may be unable to provide you with some of your desired content and services. Most browsers are configured to accept cookies by default, but you can update these settings to either refuse cookies altogether or to notify you when a Websites is trying to set or update a cookie. If you browse websites from multiple devices, you may need to update your settings on each individual device. Although some cookies can be blocked with little impact on your experience of a Websites, blocking all cookies may mean you are unable to access certain features and content across the sites you visit.
      
      How We Use Your Personal Information
      We may use your Personal Information for the following purposes, as well as for any other purpose to which you consent or is required by law:
      
      Conduct basic business operations, such as communicate with customers and business planning;
      Provide our Services;
      Provide the information about Services you have requested;
      Communicate with you about Services and our events, to the extent that you have requested such communications and with the opportunity to opt-out;
      Improve our Services and Websites;
      Verify your identity to ensure security;
      For internal record keeping and administrative matters;
      Respond to a legitimate legal request from law enforcement authorities or other government regulators;
      Investigate suspected or actual illegal activity;
      Support the sale or transfer of all or a portion of our business or assets (including through bankruptcy);
      Contact you with regard to an application or opportunity or communicate changes;
      Provide you with opportunities to offer feedback on your experience;
      Conduct analysis on applicant trends to understand and improve our marketing practices, including for diversity, equity, and inclusion analysis;
      Verify your identity and ensure security for one of the other purposes identified herein;
      Protect against fraud; and/or
      To share with our contracted vendors for purposes of hiring; and/or conduct investigations to ensure compliance with, and comply with, legal obligations.
      To enable you to customize or personalize your experience of our Websites;
      For analytics, market research, and business development, including to operate and improve our Websites, associated applications, and associated social media platforms;
      To run competitions and/or offer additional benefits to you;
      For advertising and marketing, including to send you promotional information about our products and services and information about third parties that we consider may be of interest to you;
      Allow you to apply for employment with us and evaluate your application, including without limitation arranging for and conducting phone/video conference screening, interviews, and other applicable assessments;
      Invite you to apply for and consider you for other opportunities that may be or become available;
      Contact you with regard to an application or opportunity or communicate changes;
      Validate reference checks, conduct criminal history and background checks as appropriate;
      Facilitate your hiring and administer your employment, if you are hired;
      Comply with legal and regulatory requirements involving job applications, which may include providing reports to government agencies;
      Provide you with opportunities to offer feedback on your experience;
      Conduct analysis on applicant trends to understand and improve our recruitment practices, including for diversity, equity, and inclusion analysis;
      To comply with our legal obligations and resolve any disputes that we may have; and
      To consider your employment application.
      How We Disclose or Otherwise Share Your Personal Information with Third Parties
      Personal information submitted will not be transferred to any non-affiliated third parties unless otherwise stated at the time of collection. Personally identifiable information submitted by users will only be used for the purpose stated at the time of collection and will not be sold or otherwise shared outside our family of companies, except to:
      
      Third-party service providers for the purpose of enabling them to provide their services, including (without limitation) IT service providers, data storage, hosting and server providers, ad networks, analytics, error loggers, debt collectors, maintenance or problem-solving providers, marketing or advertising providers, professional advisors, and payment systems operators;
      Allow service providers we have retained to perform Services on our behalf. In those cases, we will only share the information with service providers for the purposes outlined above. The categories of service providers with which we may share your Personal Information include ensuring our Services are provided to you. These service providers are or will be contractually restricted from using or disclosing the information except when it is necessary to perform services on our behalf or to comply with legal requirements.
      Comply with legal obligations, including but not limited to complying with tax and regulatory obligations, sharing data with labor/trade unions and works councils, and responding to a court proceeding or a legitimate legal request from law enforcement authorities or other government regulators.
      Investigate suspected or actual policy, ethics, or compliance violations or illegal activity.
      Prevent physical harm and protect employee safety.
      Support the sale or transfer of all or a portion of our business or assets (including through bankruptcy).
      Our employees, contractors, and/or related entities;
      Sponsors or promoters of any competition we run;
      Credit reporting agencies, courts, tribunals, and regulatory authorities, in the event you fail to pay for goods or services we have provided to you;
      Courts, tribunals, regulatory authorities, and law enforcement officers, as required by law, in connection with any actual or prospective legal proceedings, or in order to establish, exercise, or defend our legal rights;
      Third parties, including agents or sub-contractors, who assist us in providing information, products, services, or direct marketing to you; and
      Third parties to collect and process data.
      Where We Store Your Personal Information
      We are a U.S. company with operations only in the U.S. As such, your personal information will be stored in the U.S.
      
      How Long We Retain Your Personal Information
      We don’t keep personal information for longer than is necessary. While we retain this information, we will protect it within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use, or modification. That said, we advise that no method of electronic transmission or storage is 100% secure and cannot guarantee absolute data security. If necessary, we may retain your personal information for our compliance with a legal obligation or in order to protect your vital interests or the vital interests of another natural person.
      
      Our Policy on the Personal Information of Minors
      This Websites is intended for visitors who are at least 18 years of age and the minimum age for legal consent in their jurisdiction of residence. We do not knowingly solicit information from, or market products or services to, children. If you believe that we have collected information about a minor, please contact us immediately through the Contact Us section below. If you do not meet the age requirements set out above, please do not enter your personal information on this or any other Websites.
      
      Your Rights Regarding Your Personal Information
      Electronic Communications: To unsubscribe from our e-mail database or opt-out of text/SMS communications (including marketing communications), please Contact Us using the details below or via the opt-out facilities provided in the communications themselves.
      
      California Shine the Light Law: California residents may annually request and obtain information that is shared with other businesses for their own direct marketing use within the prior calendar year. We do not share your Personal Information with other businesses for their own direct marketing use.
      
      Nevada Resident Rights: Nevada law permits users who are Nevada consumers to request that their personally identifying information not be sold (as defined under applicable Nevada law), even if their personally identifying information is not currently being sold. Requests may be sent to support@dither.com.
      
      Complaints: If you believe that we have breached a relevant data protection law and wish to make a complaint, please contact us using the details below and provide us with full details of the alleged breach. We will promptly investigate your complaint and respond to you, in writing, setting out the outcome of our investigation and the steps we will take to deal with your complaint. You also have the right to contact a regulatory body or data protection authority in relation to your complaint.
      
      Changes to this Privacy Policy
      As we expand and improve the Websites and Services, we may need to update this Privacy Policy. If we make any significant changes to the Privacy Policy, we will post a notice on our Websites or contact you to inform you when required by law. By continuing to interact with us after the modified version of Privacy Policy has been posted, you are accepting the changes to the Privacy Policy, subject to any additional requirements that may apply. We encourage you to review this Privacy Policy on a regular basis for any changes. The date of the latest version will be identified at the bottom of the Privacy Policy.
      
      Do Not Track
      Currently, we do not employ Do Not Track Technology.
      
      License
      Subject to the terms hereof, we hereby grant you, and you accept, a personal, limited, nonexclusive, non-commercial, non-transferable, non-sublicensable and fully revocable limited license:
      
      To download and use the App on your authorized mobile phone, device or tablet that you own or control, solely for the limited purpose of using the App for your internal non-commercial use, and for no other purpose, strictly in accordance with this Privacy Policy, the applicable Usage Rules (defined below) and applicable law; and
      To use the App (including the Content) in accordance with the terms included in this Privacy Policy.
      Any use of the App or the Services other than as specifically authorized herein is strictly prohibited and, without limiting other available remedies, may result in automatically and immediately terminating the license granted herein. Such unauthorized use may also violate applicable laws including without limitation copyright and trademark laws and applicable communications regulations and statutes. Unless explicitly stated herein, nothing in this Privacy Policy shall be construed as conferring any license to intellectual property rights, whether by estoppel, implication, or otherwise. This license is revocable by us at any time.
      
      This license extends to your use of the App via personal mobile devices that you own and control, in executable, machine-readable, object code form only and solely for your personal, non-commercial purposes. If your mobile phone, device or tablet is owned by your employer or third party, then you need to obtain such employer or third party’s consent prior to installing or using the App on your phone, device or tablet.
      
      In addition, subject to your compliance in all material respects with this Privacy Policy, if you access or use the App on Apple iOS or Google Play Store, the usage rules set forth in the iTunes App Store Terms of Service and the Google Play Store Terms of Service, as applicable.
      
      If you make an in-app purchase in the App, the applicable fee may be denominated in your local currency in the iTunes App Store (http://itunes.apple.com), Google Play Store (https://play.google.com/store/apps) or any other applicable app store through which you obtain the Mobile App (each, an “App Store”). The App Store collects the applicable fee from you on your device. You should consult the App Store to understand its sales terms and determine whether the fee includes all applicable taxes, currency exchange settlements, and other charges. You are solely responsible for paying all such taxes, fees, and other charges. We rely on the App Store to collect fees and to report on the status of accounts. Your access to the App may be suspended or terminated if you do not make payment on time or in full. In-app purchases are managed by the App Store directly. We do not have the ability to manage any aspect of your in-app purchases on your behalf, including, but not limited to, initiating, canceling, or refunding purchases.
      
      If you use the Service on an Apple device, then you agree and acknowledge that:
      
      Apple, Inc. bears no duties or obligations to you under the Terms, including, but not limited to, any obligation to furnish you with Service maintenance and support;
      You will have no claims, and you waive any and all rights and causes of action against Apple with respect to the Service or the Terms, including, but not limited to claims related to maintenance and support, intellectual property infringement, liability, consumer protection, or regulatory or legal conformance;
      Apple and Apple’s subsidiaries are third-party beneficiaries of the Terms. Upon your acceptance of the Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as a third-party beneficiary thereof.
      iOS application may use Apple Services in order to work properly. That includes iCloud services, Apple In-App Purchase service, etc.
      
      Android application may use Google Play Services in order to work properly. That includes Google Maps services, Google Analytics, Google In-app Billing, and other Google applications.
      
      Notification of a Data Breach
      We will comply with laws applicable to us in respect of any data breach.
      
      Third Party Links
      Our Websites may link to external sites that are not operated by us. Please be aware that we have no control over the content and policies of those sites and cannot accept responsibility or liability for their respective privacy practices.
      
      Changes to this Privacy Policy
      At our discretion, we may change our privacy policy to reflect current acceptable practices. We will take reasonable steps to let users know about changes via our Websites. Your continued use of this site after any changes to this policy will be regarded as acceptance of our practices around privacy and personal information.
      
      If we make a significant change to this privacy policy, for example, changing a lawful basis on which we process your personal information, we will ask you to re-consent to the amended privacy policy.
      
      Contact Us
      If you have any comments or questions about this Privacy Policy, please email us at support@dither.com.</div>
  </div>
</div>


<style>


</style>