<script>
	import Fa from 'svelte-fa'
  import Dispatch from '../../../Dispatcher'
	import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons'
	import { faBars, faGlobePointer } from '@fortawesome/pro-regular-svg-icons'
	import { of, animationFrames, timer, merge, from, pipe } from 'rxjs'
  import { map, tap, mergeMap, pairwise, scan, share, startWith, switchMap, takeWhile, throttleTime, take, withLatestFrom, shareReplay } from 'rxjs/operators'
  import _ from 'lodash'
  
  import PlayerDetails from './PlayerDetails.svelte'
  import Purchase from './Purchase.svelte'
  import SuccessPurchase from './SuccessPurchase.svelte' 

  import Badge_Round2 from '../../../assets/Badge_Round2.png'
  import Badge_Top10  from '../../../assets/Badge_Top10.png'
  import Badge_Bullseye from '../../../assets/Badge_Bullseye.png'
  import Badge_Laser from '../../../assets/Badge_Laser.png'
  import Badge_BadVibes from '../../../assets/Badge_BadVibes.png'

  let teamGuid = ''
  let passcode = ''
  let discountCode = ''

  let players = []
  let team = {}

  let showCart = false

  let showMailingAddress = false

  // Set Team
  Dispatch.getState('team')
    .subscribe(e => team = (e ?? {}))

  Dispatch.getState('team.discountCode')
    .subscribe(e => discountCode = (e ?? ''))

  const players$ = Dispatch.getState('players')
    .pipe(
      tap(e => { players = e ?? [] }),
      shareReplay(1)
    )
      
  const activePlayers$ = players$
    .pipe(
      map(e => _.filter(e, player => player.active))
    )
      
  const inactivePlayers$ = players$
    .pipe(
      map(e => _.filter(e, player => !player.active))
    )
  // const verify$ = Dispatch.getAction('verifyTeam')
  //   .pipe(
  //     mergeMap(e => from(fetch(import.meta.env.VITE_DEV_SERVER + '/verifyTeam', {
  //         method: 'POST',
  //         headers: {
  //           'content-type': 'application/json'
  //         },
  //         body: JSON.stringify({
  //           guid: teamGuid,
  //           passcode: passcode
  //         })
  //       }))
  //       .pipe(
  //         mergeMap(e => e.json())
  //       )
  //     ),
  //     withLatestFrom(teams$),
  //     tap(([e, teams]) => e && Dispatch.mergeState('answers', { team: _.find(teams, team => team.guid === teamGuid), passcode })),
  //     map(([e, teams]) => e),
  //     tap(e => e && Dispatch.nextAction('nextPage')),
  //     startWith(null)
  //   )

  const initialHeight$ = merge(
			of(window),
			Dispatch.getAction('resize')
		)
		.pipe(
			map(e => e.innerHeight - 40), 
		)

  const showCart$ = Dispatch.getState('showCart')

  const cart$ = Dispatch.getState('cart')

  const purchaseSuccess$ = merge(showCart$.pipe(map(e => false)), Dispatch.getState('purchaseSuccess'))
    .pipe(
      startWith(false),
      shareReplay(1)
    )

  const referrer$ = Dispatch.getState('referrer')

  window?.gtag('event', 'manage_team', {});

</script>

<div class='w-100 mw6 mb5 pa4 pv5 pa5-ns ba br4 bg-happy-yellow black flex flex-column justify-center items-center'>
  {#if $showCart$}
    <div class='fixed  w-100 vh-100 bg-black' style='opacity: .5; top: 0px; z-index:2' on:click={e=>Dispatch.nextState('showCart', false)}></div>
    <div class=' w-100 ph3 flex justify-center' style="position: fixed; left: 0px; top:20px; z-index:1000000000; overflow-y: scroll; height:{$initialHeight$}px;">
      <div class='w-100 mw6 ba br4' >
        {#if $purchaseSuccess$}
          <SuccessPurchase/>
        {:else}
          <Purchase/>
        {/if}
      </div>
    </div>
  {/if}
  {#if $cart$?.ticketsNeeded > 0 && !$showCart$}
    <div class='ph4 w-100 flex justify-center' style="position: fixed; left: 0px; bottom:20px; z-index:100000000;"> 
      <div class='w-100 mw6 ph4-ns'>
        <div class='pv3 ph3 ba b--black br3 bg-blue tc white fw5 f4' on:click={e => Dispatch.nextState('showCart', true)}>Purchase Tickets ({$cart$?.ticketsNeeded})</div>
      </div>
      </div>
  {/if}
  <div class=' f4 fw6 tl'>
    <div class='ph3 pv3 br3 f2 fw7 tc happy-yellow bg-black'>Team Manager</div> 
  </div>
  <div class='w-100 pt5'>
    <div class='w-100 flex flex-column'>
      {#if $referrer$ === 'create'}
        <div class=' br4 tl f4 fw4'>Congratulations! <br/> <br/> An email has been sent to you with sign up details and a special link to let you modify your team at any time. 
          Only share the link with people you want to have access to your team. <br/> <br/> Use this page to create and modify your team! <br/> <br/> Good luck!
        </div>
        <div class='w-100 mb5 mt4 bg-black' style='height:1px'></div>
      {/if}
      <div class='pl1 f3 fw7 underline'>Team Name</div>
      <div class='pt3 flex items-center'>
        <input class='w-100 ph3 pv3 br3 bg-white ba b--moon-gray fw7 f5 tl' on:blur={e => Dispatch.nextAction('updateTeam', team)} bind:value={team.name}/>
      </div>
      <div class='pt4 pl1 f3 fw7 underline'>Team Bio</div>
      <div class='pt3 flex items-center'>
        <textarea class='w-100 h4 pv3 br3 ba b--moon-gray tl ph3 fw7 f5' on:blur={e => Dispatch.nextAction('updateTeam', team)} placeholder="Tell us about your awesomeness!" id="comments" name="comments"  bind:value={team.bio}></textarea>
      </div>
      {#if team?.badges?.length > 0}
        <div class='pt4 pl1 f3 fw7 underline'>Badges</div>
        <div class='pt4 flex flex-column items-center justify-center'>
        {#each team.badges ?? [] as badge, index}
            <div class='w-100' style='max-width:260px;'>
              <img src={badge.logo} />
            </div>
            <div class='pt2 pb3 ph4 f4 fw7 tc'>{badge.name}</div>
        {/each}
        </div>
      {/if}
      <div class='pt4 pl1 f3 fw7 underline'>Locked Badges</div>
      <div class='pt3 flex flex-wrap items-center justify-center'>
        <div class='ml1' style='width:90px;'>
          <img  src={Badge_Round2} />
        </div>
        <div class='ml1' style='width:90px;'>
          <img src={Badge_BadVibes} />
        </div>
        <div class='ml1' style='width:90px;'>
          <img src={Badge_Top10} />
        </div>
        <div class='ml1' style='width:90px;'>
          <img src={Badge_Bullseye} />
        </div>
        <div class='ml1' style='width:90px;'>
          <img src={Badge_Laser} />
        </div>
      </div>
      <!-- <div class='pt3 f6 fw7'>Secret Code</div>
      <div class='f6 fw5'>Only share this code with people who you want to have access to managing your team</div>
      <div>{$team$.passcode}</div>
      <input class='mt1 ph2 pv2 br3 bg-white bn fw7 f4 tl' bind:value={teamName}/> -->
      <div class='pt4 f3 fw7 underline'>Players</div>
      <div class='pt2'>You will need 3 to 6 players on your team.</div>
      {#if $activePlayers$?.length !== 0}
        <div class='pt3'>Tickets Purchased: {team?.tickets}</div>
        <div class='pt1'>Player Count: {$activePlayers$.length ?? 0}</div>
      {/if}
        {#each $activePlayers$ ?? [] as player, index}
          <PlayerDetails player={player} index={index}/>
        {/each}
        {#if $activePlayers$?.length < 6}
        <div class='pt5 flex items-center justify-center flex-column'>
          <div on:click={e => Dispatch.nextAction('upsertPlayer', {})}><Fa class='f1 blue' icon={faPlusCircle} /> </div>
          <div class='pt2 fw7 f4'>Add Player</div>
        </div>
      {/if}
        <!-- {#if $activePlayers$?.length !== 0}
        <div class='mt5 w-100 bg-black' style='height:1px;'></div>
        {/if} -->
          <!-- {#if $activePlayers$?.length < 7}
          <div class='ph3'>
            <div class='pt3 tl'>To add players, purchase a player slot. A player slot allows you to create a new player or activate an existing player.</div>
            <div class='mt4 pv3 ph3 bg-blue white tc fw7 br3'>Purchase Player Slot(s)</div>
          </div>
          {/if} -->
      <div class='pt5 pl1 f3 fw7 underline'>Mailing Address</div>
      <div class='pt2 f5 pl1'>Optional. Used for mailing badges and flags.</div>
      <div class='w-100 pt3 flex flex-column'>
        <div class='flex'>
          <input class='w-100 ph3 pv3 br3 bg-white ba b--moon-gray fw7 f5 tl' placeholder="Street" on:blur={e => Dispatch.nextAction('updateTeam', team)} bind:value={team.street}/>
        </div>
        <div class='w-100 pt2 flex'>
          <input class='w-100 ph3 pv3 br3 bg-white ba b--moon-gray fw7 f5 tl' placeholder="City" on:blur={e => Dispatch.nextAction('updateTeam', team)} bind:value={team.city}/>
          <div class='pl2'></div>
          <input class='w3 ph3 pv3 br3 bg-white ba b--moon-gray fw7 f5 tl' placeholder="Zip" on:blur={e => Dispatch.nextAction('updateTeam', team)} bind:value={team.zip}/>
        </div>
      </div>
      {#if team?.discountUsed}
        <div class='pt4 f3 fw7 underline'>Discount Code</div>
        <div class='pt3 f5 fw7 tl'>Team discount code already applied</div>
      {:else}
        <div class='pt4 f3 fw7 underline'>Discount Code</div>
        <div class='pt3 flex items-center'>
          <input class='w-100 ph3 pv3 br3 bg-white ba b--moon-gray fw7 f5 tl' on:blur={e => Dispatch.nextAction('updateDiscountCode', discountCode)} bind:value={discountCode}/>
        </div>
      {/if}
      <div class='pt4 f3 fw7 underline'>Inactive Players</div>
      {#each $inactivePlayers$ ?? [] as player, index}
        <PlayerDetails player={player} index={index}/>
          <!-- <option value={player.guid}>{player.name}</option> -->
        {/each}
        {#if $inactivePlayers$?.length === 0}
          <div class='w-100 pt5 tc f5 fw7'>No Inactive Players</div>
        {/if}
      <!-- <div class='w-100 flex justify-between'>
        <div></div>
        <div class='mt5 ph4 pv3 br4 fw7 bg-blue happy-yellow f4' on:click={e => {
          if (teamGuid !== '' && passcode !== '') {
            Dispatch.nextAction('verifyTeam', { guid: teamGuid, passcode})
            //Dispatch.mergeState('answers', { team: teamGuid, passcode }); 
            //Dispatch.nextAction('nextPage')
          }
        }}>Join</div>
      </div> -->
      
    </div>
  </div>
</div>

<style>
</style>