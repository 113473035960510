<script>
	import Fa from 'svelte-fa'
  import Dispatch from '../../../Dispatcher'
	// import { faLockAlt, faWallet, faHandHoldingUsd } from '@fortawesome/pro-solid-svg-icons'
	import { faBars, faGlobePointer } from '@fortawesome/pro-regular-svg-icons'
	import { of, animationFrames, timer, merge, from } from 'rxjs'
  import { map, tap, mergeMap, pairwise, scan, share, startWith, switchMap, filter, takeWhile, throttleTime, take, shareReplay } from 'rxjs/operators'
  import _ from 'lodash'
  import { uniqueNamesGenerator, adjectives, colors, animals } from 'unique-names-generator'

  let teamName = uniqueNamesGenerator({
    dictionaries: [adjectives, colors, animals],
    style: 'capital',
    separator: ' ',
  });

  let playerEmail = ''
  let error = ''

  let creating = false;

  window?.gtag('event', 'conversion_event_add_to_cart', {});

  // Dispatch.getState('answers.team')
  //   .pipe(
  //     take(1),
  //   )
  //   .subscribe(e => {
  //     teamName = e.name ? e.name : ''
  //   })

  Dispatch.getAction('createTeam')
    .pipe(
      tap(e => { creating = true }),
      tap(e =>  window?.gtag('event', 'create_team', {})),
      mergeMap(e => from(fetch(import.meta.env.VITE_DEV_SERVER + '/createTeam', {
          method: 'POST',
          headers: {
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            name: teamName,
            email: playerEmail,
          })
        }))
        .pipe(
          mergeMap(e => e.json())
        )
      ),
      tap(e => { creating = false }),
    )
    .subscribe(e => {
      Dispatch.nextState('referrer', 'create')
      Dispatch.nextState('team', e)
      if (e?.guid) {
        const urlParams = new URLSearchParams(window.location.search);
        					// Set or modify parameters
        urlParams.set('passcode', e.passcode)

        // Replace the current state in the history (does not reload the page)
        history.replaceState({}, '', `${location.pathname}?${urlParams}`);
      }
    })

   const teamError$ = Dispatch.getState('team')
        .pipe(
          filter(e => _.isNil(e)),
          map(e => "Team name already exists. Please choose another name.")
        )

</script>

<div class='w-100 mw6 mb5 ph4 pv4 br4 pa5-ns ba br4 bg-happy-yellow flex flex-column'>
  <div class='f4 fw5 black tl'>
    <div class='f2 fw7'>Welcome</div>
    <div class='pt1 f3 fw7'>Let's create your team! </div>
    <!-- <div class='f4 pt4 fw4'>Each team consists of up to 6 players, but a minimum of 3 will be required.</div>
    <div class='pt3 f5 fw7'>Note: Don't worry, you can change your team name anytime!</div> -->
  </div>
  <!-- <input class='w-100 mt4 ph1 pv3 br4 bg-white bn fw7 f4 tc' placeholder='Team Name' bind:value={teamName}/> -->
  <input class='w-100 mt4 ph1 pv3 br4 bg-white bn fw7 f4 tc' placeholder='Your Email' bind:value={playerEmail}/>
  {#if $teamError$}
    <div class='pt3 red '>{$teamError$}</div>
  {/if}
  <div class='w-100 flex justify-between pointer'>
    <div></div>
    <div class='mt4 ph3 pv3 br4 fw7 bg-blue happy-yellow f4' on:click={e => {
      if (teamName !== '' && playerEmail !== '') {
        Dispatch.nextAction('createTeam')
      }
    }}>{creating ? 'Creating' : 'Create'}</div>
  </div>
</div>

<style>
</style>