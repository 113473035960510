<script>
	import Fa from 'svelte-fa'
  import Dispatch from '../Dispatcher'
	// import { faLockAlt, faWallet, faHandHoldingUsd } from '@fortawesome/pro-solid-svg-icons'
	import { faBars } from '@fortawesome/pro-regular-svg-icons'
	import { delay, map, of, tap, animationFrames, takeUntil, timer } from 'rxjs'
  import { mergeMap, pairwise, scan, share, startWith, switchMap, takeWhile, throttleTime, withLatestFrom } from 'rxjs/operators'
  import _ from 'lodash'

  const menu = [
			{
				name: 'Home',
				link: '',
				page: 'home',
			},
			{
				name: 'Intro',
				type: 'scroll',
				link: 'intro',
				page: 'home',
			},
			{
				name: 'Details',
				type: 'scroll',
				link: 'tickets',
				page: 'home',
			},
			{
				name: 'FAQ',
				type: 'scroll',
				link: 'faq',
				page: 'home',
			},
			// {
			// 	name: 'Technical',
			// 	type: 'scroll',
			// 	link: 'technical'
			// },
			// {
			// 	name: 'Case Study',
			// 	type: 'scroll',
			// 	link: 'caseStudy'
			// },
			// {
			// 	name: 'East Troy Game',
			// 	type: 'link',
			// 	link: 'game.smashingkeyboards.com'
			// },
			// {
			// 	name: 'About Us',
			// 	link: ''
			// },
			{
				name: 'Contact',
				type: 'scroll',
				link: 'contact',
				page: 'home',
			},
		]

	Dispatch.getAction('menu.open')
		.pipe(
			scan((acc,next) => _.isNil(next) ? !acc : next, false),
			startWith(false)
		)
		.subscribe(e => Dispatch.nextState('view.menu.open', e))

		const menuOpen = Dispatch.getState('view.menu.open')
	
	Dispatch.getAction('menu.click')
		.pipe(
		)
		.subscribe(e => {
			Dispatch.nextAction('navigate', e)
			// if (e.type === 'link'){
			// 	window.location.href = e.link;
			// }
			// if (e.type === 'search'){
			// 	Dispatch.nextAction('updateURLParam', {key: 'page', value: e.page})
			// }
			// else if (e.type === 'scroll') {
			// 	Dispatch.nextAction('navigate', e)
			// 	document.getElementById(e.link).scrollIntoView();
			// }
		})
</script>

{#if $menuOpen}
  <div class='absolute ' style='top:50px; left:0px; z-index:2000;'>
    <div class=' pa4'>
      <div class='pb4 br2 tastygreen ba b--dark-gray' style='background-color: rgba(15,15,15,.95);' on:click={e => {e.stopPropagation()}}>
        <!-- <div class='ph3 pt3 w-100 flex justify-between'>
          <div></div>
          <div class='white fw7' on:click={e => Dispatch.nextAction('menuOpen')}>X</div>
        </div> -->
        <div class='pt4 ph4 f4 fw4 white '>
          {#each menu as item}
            <div class='' on:click={e => Dispatch.nextAction('menu.click', item)}>
              <div class='w-100 ph2 pt3 pb3 tr'>{item.name}</div>
              <div class='w-100 bg-dark-gray' style='height:1px'></div>
            </div>
          {/each}
        </div>
      </div>
    </div>
  </div>
{/if}

<style>

</style>