<script>
	import Fa from 'svelte-fa'
  import Dispatch from '../Dispatcher'
	// import { faLockAlt, faWallet, faHandHoldingUsd } from '@fortawesome/pro-solid-svg-icons'
	import { faBars, faGlobePointer } from '@fortawesome/pro-regular-svg-icons'
	import { of, animationFrames, timer, merge } from 'rxjs'
  import { map, tap, mergeMap, pairwise, scan, share, startWith, switchMap, takeWhile, throttleTime } from 'rxjs/operators'
  import _ from 'lodash'

  import Menu from './Menu.svelte'

</script>

<!-- Mobile Header -->
<div class='w-100 di dn-ns'>
  <div class='w-100 ph4 pb2 pt3 bg-happy-yellow flex flex-column'>
    <Menu />
    <div class='flex justify-between items-center black w-100'>
      <div class='f4 f2-ns fw8 tl' on:click={e => Dispatch.nextAction('navigate', {page:'home'})}>SCAVENGER GAMES</div>
      <div class='f3 f1-ns fw2'>|</div>
      <div class='f5 f3-ns fw5 tr'>East Troy, WI</div>
    </div>
    <div class=' w-100 flex justify-between items-center'>
      <div class=' black relative f2 pr4' style='right:0px; top:5px;' on:click={e => Dispatch.nextAction('menu.open')}><Fa icon={faBars}/></div>
      <div class='flex flex-column'>
        <div class='mt2 ph3 pv2 br3 bg-blue white fw7 f4' on:click={e => Dispatch.nextAction('navigate', {
          name: 'Details',
          type: 'scroll',
          link: 'tickets',
          page: 'home',
        })}>Sign Up</div>
      </div>
    </div>
  </div>
</div>

<!-- Desktop Header -->
<div class='dn flex-ns w-100 ph4 pb2 pt2 bg-happy-yellow justify-between items-center'>
  <div class=' black relative f2 pr4' style='right:0px; top:5px;' on:click={e => Dispatch.nextAction('menu.open')}><Fa icon={faBars}/></div>
  <Menu />
  <div class='flex black items-center'>
    <div class='f4 f2-ns fw8 tl pointer' on:click={e => Dispatch.nextAction('navigate', {page:'home'})}>SCAVENGER GAMES</div>
    <div class='f3 f1-ns fw2 pl3'>|</div>
    <div class='f5 f3-ns fw5 tr pl3'>East Troy, WI</div>
  </div>
  <div class='mt2 ph3 pv3 br4 bg-blue white fw7 f4 pointer' on:click={e => Dispatch.nextAction('navigate', {
    name: 'Details',
    type: 'scroll',
    link: 'tickets',
    page: 'home',
  })}>Sign Up</div>
</div>

<style>

</style>