<script>
	import Fa from 'svelte-fa'
	import Dispatch from './Dispatcher'
	// import { faLockAlt, faWallet, faHandHoldingUsd } from '@fortawesome/pro-solid-svg-icons'
	import { faBars, faGlobePointer } from '@fortawesome/pro-regular-svg-icons'
	import { delay, map, of, tap, animationFrames, merge, timer, from } from 'rxjs'
	import { switchMap, startWith, share, mergeMap, finalize, pairwise, takeWhile, scan } from 'rxjs/operators'
	import _ from 'lodash'
	
	import Menu from './components/Menu.svelte'
	import Signup from './components/Signup/Signup.svelte'
	import HomePage from './components/HomePage.svelte'
	import Terms from './components/Terms.svelte'
	import Privacy from './components/Privacy.svelte'
	import Waiver from './components/Waiver.svelte'

	$: innerHeight = 0
	$: outerHeight = 0
  $: innerWidth = 0

	// window.addEventListener('resize', function(event) {
	// 		Dispatch.nextAction('resize', window)
	// }, true);

	const initialHeight = merge(
			of(window),
			Dispatch.getAction('resize')
		)
		.pipe(
			map(e => e.innerHeight), 
		)

  	const initialWidth = merge(
			of(window),
			Dispatch.getAction('resize')
		)
		.pipe(
			map(e => e.innerWidth > 550 ? 550 : e.innerWidth - 45), 
		)

		const menuOpen = Dispatch.getState('view.menu.open')


		const urlParams = new URLSearchParams(window.location.search);

		const connectDev = !import.meta.env.PROD

		const page$ = merge(of({page: urlParams.get('page')}), Dispatch.getAction('navigate'))
		.pipe(
			map(e => e.page)
		)

		of(urlParams.get('passcode'))
			.pipe(
			)
		.subscribe(e => Dispatch.nextState('passcode', e))

		Dispatch.getAction('navigate')
			.pipe(
				tap(e => {

					const urlParams2 = new URLSearchParams(window.location.search);
					// Set or modify parameters
					urlParams2.set('page', e.page);

					// Replace the current state in the history (does not reload the page)
					history.replaceState({}, '', `${location.pathname}?${urlParams2}`);
				}),
				delay(20),
			)
			.subscribe(e => {
				if (e.type === 'scroll'){
					document.getElementById(e.link).scrollIntoView();
				}
				
			})

</script>

<svelte:window bind:innerHeight bind:outerHeight bind:innerWidth/>

<main class='avenir' on:click={e => $menuOpen && Dispatch.nextAction('menu.open', false)}>
	{#if $page$ === 'tickets'}
		<Signup />
	{:else if $page$ === 'terms'}
		<Terms />
	{:else if $page$ === 'privacy'}
		<Privacy />
	{:else if $page$ === 'waiver'}
		<Waiver />
	{:else}
		<HomePage />
	{/if}
	
	
</main>

<style>
	:global(.tastygreen) {
  color: rgb(156, 211, 75)
	}

	:global(.bg-tastygreen) {
		background-color: rgb(156, 211, 75)
	}

	:global(.b--tastygreen) {
		border-color: rgb(156, 211, 75)
	}

	:global(.happy-yellow) {
  	color: rgb(255, 242, 0)
	}

	:global(.bg-happy-yellow) {
  	background-color: rgb(255, 242, 0)
	}

	:global(.b--happy-yellow) {
  	border-color: rgb(255, 242, 0)
	}

	::placeholder {
		color: rgb(255, 242, 0);
		opacity: 1; /* Firefox */
	}

	.block {
			width: 100%;
			position: absolute;
			bottom: 0px;
			top: 0px;
			box-shadow: inset 0px 0px 60px 40px black;
			/* box-shadow-color: black;
			box-shadow-offset-x: 0px;
			box-shadow-offset-y: 0px; */
	}

	@media (min-width: 640px) {
		main {
			max-width: none;
		}
	}
</style>