import Dispatch from '../Dispatcher'
// import { faLockAlt, faWallet, faHandHoldingUsd } from '@fortawesome/pro-solid-svg-icons'
import { faBars, faGlobePointer } from '@fortawesome/pro-regular-svg-icons'
import { of, animationFrames, timer, merge, from, combineLatest } from 'rxjs'
import { map, tap, mergeMap, pairwise, scan, share, filter, startWith, switchMap, takeWhile, throttleTime, take, withLatestFrom } from 'rxjs/operators'
import _ from 'lodash'

  Dispatch.getState('passcode')
    .pipe(
      filter(e => !_.isNil(e)),
      mergeMap(e => from(fetch(import.meta.env.VITE_DEV_SERVER + '/team', {
          method: 'POST',
          headers: {
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            passcode: e
          })
        }))
        .pipe(
          mergeMap(e => e.json())
        )
      ),
    )
    .subscribe(e => {
      Dispatch.nextState('team', e)
    })

  Dispatch.getState('team')
    .pipe(
      filter(e => !_.isNil(e?.guid)),
      map(e => e.players),
      map(e => _.sortBy(e, 'guid'))
    )
    .subscribe(e => Dispatch.nextState('players', e))

  Dispatch.getAction('updateTeam')
    .pipe(
      mergeMap(team => from(fetch(import.meta.env.VITE_DEV_SERVER + '/updateTeam', {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          team
        })
      }))
      .pipe(
        mergeMap(e => e.json())
      )
    ),
    )
    .subscribe(e => Dispatch.nextState('team', e))

Dispatch.getAction('upsertPlayer')
  .pipe(
    withLatestFrom(Dispatch.getState('team')),
    mergeMap(([player, team]) => from(fetch(import.meta.env.VITE_DEV_SERVER + '/upsertPlayer', {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          player,
          team
        })
      }))
      .pipe(
        mergeMap(e => e.json())
      )
    ),
  )
  .subscribe(e => Dispatch.nextState('team', e))


Dispatch.getAction('updateDiscountCode')
  .pipe(
    withLatestFrom(Dispatch.getState('team')),
    mergeMap(([discountCode, team]) => from(fetch(import.meta.env.VITE_DEV_SERVER + '/updateDiscountCode', {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          discountCode,
          team
        })
      }))
      .pipe(
        mergeMap(e => e.json())
      )
    ),
  )
  .subscribe(e => Dispatch.nextState('team', e))

  combineLatest(Dispatch.getState('players'), Dispatch.getState('team'))
    .pipe(
      map(([players,team, discount]) => {
        const ticketsNeeded = _.filter(players, player => player.active).length - team.tickets
        return { 
          ticketsNeeded,
          total: team?.total,
        }
      })
    )
    .subscribe(e => Dispatch.nextState('cart', e))