<script>
	import Fa from 'svelte-fa'
  import Dispatch from '../Dispatcher'
	// import { faLockAlt, faWallet, faHandHoldingUsd } from '@fortawesome/pro-solid-svg-icons'
	import { faBars, faGlobePointer, faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
	import { of, animationFrames, timer, merge } from 'rxjs'
  import { map, tap, mergeMap, pairwise, scan, share, startWith, switchMap, takeWhile, throttleTime, withLatestFrom } from 'rxjs/operators'
  import _ from 'lodash'

  import VaultHandle from '../assets/VaultHandle.png'
  import FlagOutline from '../assets/FlagOutline.png'

  import Header from './Header.svelte'


  const initialHeight$ = merge(
			of(window),
			Dispatch.getAction('resize')
		)
		.pipe(
			map(e => e.innerHeight - 99), 
		)

</script>

<div>
  <Header />
  <div id='tickets' class='w-100 bt b--black bw3 bg-black happy-yellow flex flex-column items-center' style={'min-height: ' + $initialHeight$ + 'px;'}>
    <div class='w-100 mw6 pt4 fw7 f1 tc'>Terms & Conditions</div>
    <div class='mw6 ph3 pt3 pb5 yellow' style='white-space: pre-line;'>
    Agreement to Terms of Use
    These Terms and Conditions of Use (the "Terms of Use") shall manage your use of this web site (the "Site"). BY USING THE SITE, YOU AGREE TO THE TERMS OF USE.

    East Troy Area Chamber of Commerce, along with its licensors and affiliates, reserves the right, at its sole discretion, to change, modify, add or remove portions of these Terms of Use, at any time. It is your responsibility to check these Terms of Use periodically for changes. Your continued use of the Site following the posting of changes will mean that you accept and agree to the changes.

    Intellectual Property Rights
    All text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos, sounds, music, artwork and computer code (collectively, "Content"), including but not limited to the design, structure, selection, coordination, expression, "look and feel" and arrangement of such Content, contained on the Site is owned, controlled or licensed by or to East Troy Area Chamber of Commerce, and is protected by trade dress, copyright, patent and trademark laws, and various other intellectual property rights and unfair competition laws.

    Except as expressly provided in these Terms of Use, no part of the Site and no Content may be copied, reproduced, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted or distributed in any way (including "mirroring") to any other computer, server, web site or other medium for publication or distribution or for any commercial enterprise, without East Troy Area Chamber of Commerce' express prior written consent.

    Restrictions
    You are specifically restricted from all of the following

    publishing any Site material in any other media;
    selling, sublicensing and/or otherwise commercializing any Site material;
    publicly performing and/or showing any Site material;
    using the Site in any way that is or may be damaging to the Site;
    using the Site in any way that impacts user access to the Site;
    using the Site contrary to applicable laws and regulations, or in any way may cause harm to the Site, or to any person or business entity;
    engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to the Site;
    using the Site to engage in any advertising or marketing.
    Certain areas of the Site are restricted from being accessed by you and East Troy Area Chamber of Commerce may further restrict access by you to any areas of the Site, at any time, in absolute discretion. Any user ID and password you may have for the Site are confidential and you must maintain confidentiality as well.

    DISCLAIMERS
    EAST TROY AREA CHAMBER OF COMMERCE DOES NOT PROMISE THAT THE SITE OR ANY CONTENT, SERVICE OR FEATURE OF THE SITE WILL BE ERROR-FREE OR UNINTERRUPTED, OR THAT ANY DEFECTS WILL BE CORRECTED, OR THAT YOUR USE OF THE SITE WILL PROVIDE SPECIFIC RESULTS. THE SITE AND ITS CONTENT ARE DELIVERED ON AN "AS-IS" AND "AS-AVAILABLE" BASIS. ALL INFORMATION PROVIDED ON THE SITE IS SUBJECT TO CHANGE WITHOUT NOTICE. EAST TROY AREA CHAMBER OF COMMERCE CANNOT ENSURE THAT ANY FILES OR OTHER DATA YOU DOWNLOAD FROM THE SITE WILL BE FREE OF VIRUSES OR CONTAMINATION OR DESTRUCTIVE FEATURES. EAST TROY AREA CHAMBER OF COMMERCE DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTIES OF ACCURACY, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. EAST TROY AREA CHAMBER OF COMMERCE DISCLAIMS ANY AND ALL LIABILITY FOR THE ACTS, OMISSIONS AND CONDUCT OF ANY THIRD PARTIES IN CONNECTION WITH OR RELATED TO YOUR USE OF THE SITE AND/OR ANY EAST TROY AREA CHAMBER OF COMMERCE SERVICES. YOU ASSUME TOTAL RESPONSIBILITY FOR YOUR USE OF THE SITE AND ANY LINKED SITES. YOUR SOLE REMEDY AGAINST EAST TROY AREA CHAMBER OF COMMERCE FOR DISSATISFACTION WITH THE SITE OR ANY CONTENT IS TO STOP USING THE SITE OR ANY SUCH CONTENT. THIS LIMITATION OF RELIEF IS A PART OF THE BARGAIN BETWEEN THE PARTIES.

    The above disclaimer applies to any damages, liability or injuries caused by any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communication line failure, theft or destruction of or unauthorized access to, alteration of, or use, whether for breach of contract, tort, negligence or any other cause of action.

    East Troy Area Chamber of Commerce reserves the right to do any of the following, at any time, without notice: (1) to modify, suspend or terminate operation of or access to the Site, or any portion of the Site, for any reason; (2) to modify or change the Site, or any portion of the Site, and any applicable policies or terms; and (3) to interrupt the operation of the Site, or any portion of the Site, as necessary to perform routine or non-routine maintenance, error correction, or other changes.

    Limitation of Liability
    Except where prohibited by law, in no event will East Troy Area Chamber of Commerce be liable to you for any indirect, consequential, exemplary, incidental or punitive damages, including lost profits, even if East Troy Area Chamber of Commerce has been advised of the possibility of such damages. If, notwithstanding the other provisions of these Terms of Use, East Troy Area Chamber of Commerce is found to be liable to you for any damage or loss which arises out of or is in any way connected with your use of the Site or any Content, East Troy Area Chamber of Commerce liability shall in no event exceed the greater of (1) the total of any subscription or similar fees with respect to any service or feature of or on the Site paid in the six months prior to the date of the initial claim made against East Troy Area Chamber of Commerce, or (2) US$100.00. Some jurisdictions do not allow limitations of liability, so the foregoing limitation may not apply to you.

    Indemnification
    You agree to indemnify and hold East Troy Area Chamber of Commerce, its officers, directors, shareholders, predecessors, successors in interest, employees, agents, subsidiaries and affiliates, harmless from any demands, loss, liability, claims or expenses (including attorneys’ fees), made against East Troy Area Chamber of Commerce by any third party due to or arising out of or in connection with your use of the Site.

    Severability
    If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.

    Variation of Terms
    East Troy Area Chamber of Commerce is permitted to revise these Terms at any time as it sees fit, and by using the Site you are expected to review these Terms on a regular basis.

    Assignment
    The East Troy Area Chamber of Commerce is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.

    Entire Agreement
    These Terms constitute the entire agreement between East Troy Area Chamber of Commerce and you in relation to your use of the Site, and supersede all prior agreements and understandings.

    Governing Law & Jurisdiction
    These Terms will be governed by and interpreted in accordance with the laws of the State of Wisconsin, and you submit to the non-exclusive jurisdiction of the state and federal courts located in Wisconsin for the resolution of any disputes.
  </div>
</div>
</div>


<style>


</style>