<script>
	import Fa from 'svelte-fa'
  import Dispatch from '../Dispatcher'
	// import { faLockAlt, faWallet, faHandHoldingUsd } from '@fortawesome/pro-solid-svg-icons'
	import { faBars, faGlobePointer, faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
	import { of, animationFrames, timer, merge } from 'rxjs'
  import { map, tap, mergeMap, pairwise, scan, share, startWith, switchMap, takeWhile, throttleTime, withLatestFrom } from 'rxjs/operators'
  import _ from 'lodash'

  import VaultHandle from '../assets/VaultHandle.png'
  import FlagOutline from '../assets/FlagOutline.png'

  import Header from './Header.svelte'


  const initialHeight$ = merge(
			of(window),
			Dispatch.getAction('resize')
		)
		.pipe(
			map(e => e.innerHeight - 99), 
		)

</script>

<div>
  <Header />
  <div id='tickets' class='w-100 bt b--black bw3 bg-black happy-yellow flex flex-column items-center' style={'min-height: ' + $initialHeight$ + 'px;'}>
    <div class='w-100 mw6 pt4 fw7 f1 tc'>Informed Consent and Acknowledgement</div>
    <div class='mw6 ph3 pt3 pb5 yellow' style='white-space: pre-line;'>
      I do hereby release the East Troy Area Chamber of Commerce (ETACC), and/or its agents, employees, Board of Directors, and other third persons from any liability, including both any and all civil and criminal actions, resulting from any incidents, acts or other injuries suffered from me or my child’s participation in the 2024 Scavenger Games on the 19 of October, 2024. I understand that I assume all risks and injuries associated with my, or my child’s participation in the Scavenger Games, and further waive any and all liability on the part of the ETACC, and/or its agents.
      
      Photographs, videos, and/or audio clips may be taken of participants engaging in the 2024 Scavenger Games. ETACC requests the right to use all photos, videos, and/or audio clips taken of participating youth and adults. These may be used for promotional brochures, promotions or showcase of programs on our websites, showcase of activities in local newspapers, and other purposes.

      I consent to allow the East Troy Area Chamber of Commerce and/or its affiliates, and other third persons to use photos, videos, and/or audio clips that they have of me or my child participating and I confirm that I understand and agree to the above requests and conditions. I agree to give up my rights with regards to related photos, videos, and/or audio clips of me.
    </div>
  </div>
</div>


<style>


</style>