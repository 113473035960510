<script>
	import Fa from 'svelte-fa'
	import Dispatch from '../Dispatcher'
	import { faWavePulse, faDesktop, faWindTurbine } from '@fortawesome/pro-solid-svg-icons'
	import { faBars, faGlobePointer, faWind } from '@fortawesome/pro-regular-svg-icons'
	import { delay, map, of, tap, animationFrames, merge, timer, from } from 'rxjs'
	import { switchMap, startWith, share, mergeMap, finalize, pairwise, takeWhile, scan } from 'rxjs/operators'
	import _ from 'lodash'

  import ActivationStation from '../assets/Items/ActivationStationShadow.png'
	import VibeTerminal from '../assets/Items/VibeTerminal.png'
	import VibeGenerator from '../assets/Items/VibeGenerator.png'

  const items = [
    {
      icon: faDesktop,
      name: 'Vibe Terminal',
      image: VibeTerminal,
      profile: `Originally designed to monitor fluctuations in the <span class='underline pointer'>Vibe Generators</span>, the Vibe Terminal has become a critical tool for your success. 
      <br/><br/>
      The Vibe Terminal is capable of displaying important information about the game, including your current score and recently completed objectives, while simultaneously critical to the activation of certain puzzles and objectives.`,
      io: `The Vibe Terminal is capable of accepting text based commands and calibrations. Output includes audio and visual feedback.`,
      reward: `Unlocking the Vibe Terminal rewards a medium number of points. 
      <br/><br/>
      Completion of additional objectives using the Vibe Terminal can reward a significant number of points`
    },
    {
      icon: faWavePulse,
      name: 'Activation Station',
      image: ActivationStation,
      profile: `Designed as a locational association device the Activation Station attaches your identity - through the use of your flag - to a point of interest.
        <br/><br/>
        Activation Stations generally unlock objectives, activate rooms, or begin challenges.`,
      io: `The Activation Station is capable of accepting flag inputs and ouputting a variety of important audio messages.`,
      reward: `Each Activation Station visited rewards a small number of points.`
    },
    {
      icon: faWindTurbine,
      name: 'Vibe Generator',
      image: VibeGenerator,
      profile: 'Critical to the activation of numerous puzzles, objectives, and locations, the Vibe Generator creates the power required to activate and power the devices around you.',
      io: 'The Vibe Generator accespts rotational physical force and outputs power for surrounding devices.',
      reward: 'None',
    }
  ]

  // export let icon
  // export let name
  // export let image

</script>

{#each items as item}
  <div class='w-100 mw6 mt4 pt5 ph3 pb3 ba br4 bw3 b--red bg-happy-yellow'>
    <div class='flex flex-column w-100 items-center ph4 tc'>
      <div class='flex items-center justify-center bg-red br4' style='height:80px; width:80px'>
        <Fa class='f1 happy-yellow' icon={item.icon}/>
      </div>
      <div class='w-100 pt3 tc black fw7 f2'>{item.name}</div>
      <!-- <div class='w-100 bg-black' style='height:1px'></div> -->
      <img class='pt3 ' alt='ActivationStation' src={item.image} style=''/>
    </div>
    <!-- <div class='w-100 pt3 tc white fw7 f4'>Activation Station</div> -->
    <div class='w-100 pt4 pb4 ph3 ph4-ns tl black br3 fw6 '>
    <div class='w-100 bg-black' style='height:1px'></div> 
      <div class='pt4 fw7 f4'>Profile:</div>
      <div class='pt2' >
        {@html item.profile}
      </div>
      <div class='pt4 fw7 f4'>Input/Output:</div>
      <div class='pt2'>
        {@html item.io}
      </div>
      <div class='pt4 fw7 f4'>Reward:</div>
      <div class='pt2'>{@html item.reward}</div>
    </div>
  </div>
{/each}