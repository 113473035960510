<script>
	import Fa from 'svelte-fa'
  import Dispatch from '../../../Dispatcher'
	// import { faLockAlt, faWallet, faHandHoldingUsd } from '@fortawesome/pro-solid-svg-icons'
	import { faBars, faGlobePointer } from '@fortawesome/pro-regular-svg-icons'
	import { of, animationFrames, timer, merge, from, lastValueFrom, firstValueFrom, combineLatest } from 'rxjs'
  import { map, tap, mergeMap, pairwise, scan, share, startWith, switchMap, takeWhile, throttleTime, take, catchError } from 'rxjs/operators'
  import _ from 'lodash'
  import { loadStripe } from '@stripe/stripe-js'
  import { Elements, PaymentElement } from 'svelte-stripe'
  import { onMount } from 'svelte'
    import Team from './Team.svelte';
  // import { PUBLIC_STRIPE_KEY } from '$env/static/public'

  let stripe = null
  let clientSecret = null
  let error = null
  let elements
  let processing = false

  const PUBLIC_STRIPE_KEY=import.meta.env.VITE_STRIPE_PUBLIC_KEY

  window?.gtag('event', 'begin_checkout', {});

  onMount(async () => {
    stripe = await loadStripe(PUBLIC_STRIPE_KEY)
    // create payment intent server side
    clientSecret = await createPaymentIntent()
    Dispatch.nextState('purchaseSuccess', false)
  })

  async function createPaymentIntent() {

    const response = await firstValueFrom(combineLatest(Dispatch.getState('cart'), Dispatch.getState('team'))
      .pipe(
        take(1),
        mergeMap(([cart, team]) => from(fetch(import.meta.env.VITE_DEV_SERVER + '/create-payment-intent', {
          method: 'POST',
          headers: {
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            tickets: cart?.ticketsNeeded,
            team: team
          })
        }))),
      )
    )

    const { clientSecret } = await response.json()

    return clientSecret
  }

  async function submit() {
    // avoid processing duplicates
    if (processing) return

    processing = true

    // confirm payment with stripe
    const result = await stripe.confirmPayment({
      elements,
      redirect: 'if_required'
    })

    // log results, for debugging
    console.log({ result })

    if (result.error) {
      // payment failed, notify user
      error = result.error
      processing = false
    } else {
      // payment succeeded, redirect to "thank you" page
      //goto('/examples/payment-element/thanks')
    const updatedTeam = await firstValueFrom(combineLatest(Dispatch.getState('team'), Dispatch.getState('cart'))
        .pipe(
          take(1),
          mergeMap(([team, cart]) => from(fetch(import.meta.env.VITE_DEV_SERVER + '/purchase', {
            method: 'POST',
            headers: {
              'content-type': 'application/json'
            },
            body: JSON.stringify({
              team: team,
              tickets: cart?.ticketsNeeded,
            })
          }))
        ),
        mergeMap(e => e.json()),
      )
    )

    if (!_.isNil(updatedTeam)) {
      Dispatch.nextState('purchaseSuccess', true)
      Dispatch.nextState('team', updatedTeam)
    }
    else {
      error = { message: 'An error occurred. Please use the contact form to message us.' }
    }
  }
}

const amount$ = Dispatch.getState('cart')
  .pipe(
    map(cart => cart.total)
  )
    
  const initialHeight$ = merge(
			of(window),
			Dispatch.getAction('resize')
		)
		.pipe(
			map(e => e.innerHeight - 40), 
		)
</script>

<div class='w-100 mw6 ph4 pb4 pt4 pa5-ns br4 bg-happy-yellow black flex flex-column'>
  <div class='w-100 flex justify-between'>
    <div></div>
    <div class='fw7 f3 red' on:click={e => Dispatch.nextState('showCart', false)}>X</div>
  </div>
  <div class='pt3 f4 fw4 black tc'>
    <div class='f2 fw7'>Payment </div>
    <div class='pt2'>Get ready to scavenge!</div>
  </div>

  {#if error}
  <p class="error">{error.message} Please try again.</p>
{/if}

{#if clientSecret}
  <Elements
    {stripe}
    {clientSecret}
    theme="flat"
    labels="floating"
    variables={{ colorPrimary: '#7c4dff' }}
    rules={{ '.Input': { border: 'solid 1px #0002' } }}
    bind:elements
  >
    <form on:submit|preventDefault={submit}>
      <!-- <LinkAuthenticationElement /> -->
      <PaymentElement />
      <!-- <Address mode="billing" /> -->
      <div class='pt2'>By proceeding with this purchase, you acknowledge and agree to our <a href='/?page=terms' target="_blank">Terms of Service</a> as well as acknowledge and agree to our <a href='/?page=waiver' target="_blank">Waiver.</a> </div>
      <button disabled={processing}>
        {#if processing}
          Processing...
        {:else}
          Pay ${$amount$}
        {/if}
      </button>
    </form>
  </Elements>
{:else}
  Loading...
{/if}
</div>

<style>
  .error {
    color: tomato;
    margin: 2rem 0 0;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 2rem 0;
  }

  button {
    padding: 1rem;
    border-radius: 10px;
    color: yellow;
    background: black;
    font-size: 1.2rem;
    margin: 1rem 0;
    font-weight: bolder;
  }
</style>
