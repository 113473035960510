<script>
	import Fa from 'svelte-fa'
  import Dispatch from '../../../Dispatcher'
	// import { faLockAlt, faWallet, faHandHoldingUsd } from '@fortawesome/pro-solid-svg-icons'
	import { faBars, faGlobePointer } from '@fortawesome/pro-regular-svg-icons'
	import { of, animationFrames, timer, merge } from 'rxjs'
  import { map, tap, mergeMap, pairwise, scan, share, startWith, switchMap, takeWhile, throttleTime, take } from 'rxjs/operators'
  import _ from 'lodash'

  export let player = {}
  export let index

</script>

<div class={'ba br4 mt4 pb3 pt4 ph3 w-100 b--moon-gray black flex flex-column items-center ' + (player.active ? 'bg-black' : 'bg-black')}>
  <div class='pv3 ph4 br3 f3 fw7 tc bg-happy-yellow black '>Player {index + 1}</div>
  <div class='w-100 pt3 yellow'>
    <div class='f6 fw7'>Name</div>
    <div class='w-100 flex items-center'>
      <input class='w-100 mt1 ph3 pv2 br3 bg-white ba fw5 f5 tl black' on:blur={e => Dispatch.nextAction('upsertPlayer', player)} bind:value={player.name}/>
    </div>
    <div class='pt3 f6 fw7'>Email</div>
    <div class='w-100 flex items-center'>
      <input class='w-100 mt1 ph3 pv2 br3 bg-white ba fw5 f5 tl black' on:blur={e => Dispatch.nextAction('upsertPlayer', player)} bind:value={player.email}/>
    </div>
    <div></div>
  </div>
  <div class='w-100 pt3 flex justify-between'>
    <!-- <div class='br3 ph3 pv3 bg-green white fw7'>Make Leader</div> -->
    {#if player.isCreator}
      <div class='br3 pv3 white fw7'>Leader</div>
    {:else}
      <div></div>
    {/if}
    {#if !player.active}
      <div on:click={e => Dispatch.nextAction('upsertPlayer', {...player, active: true})} class='br3 ph3 pv3 bg-green white fw7'>Activate</div>
    {:else}
      <div on:click={e => Dispatch.nextAction('upsertPlayer', {...player, active: false})} class='br3 ph3 pv3 bg-red white fw5'>Deactivate</div>
    {/if}

  </div>
</div>

<style>
</style>